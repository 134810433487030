import Icon, { ICONS } from "components/common/Icons";
import DividerButton from "components/common/divider/button";
import TopHeader from "components/common/header";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { TYPO } from "styles/Typo";

function ThemeList({ themeListData }: { themeListData?: any }) {
  const router = useRouter();
  const { t } = useTranslation("main");

  const path = router.pathname;

  let data = themeListData;
  if (path === "/") data = data.slice(0, 5);

  return (
    <div className="bg-white pt-4">
      {path === "/industry" && (
        <TopHeader
          clickHandler={() => {
            router.push("/");
          }}
        />
      )}
      <div
        className={` px-6 py-4 flex items-center w-full ${
          path === "/" ? TYPO.TYPO4_B : TYPO.TYPO3_B
        }`}
      >
        {t("trendingIndustry")}
      </div>

      <div
        className={`flex items-center justify-end ${
          TYPO.TYPO8_RG
        } text-gray40 w-full px-5 ${data.length === 0 ? "hidden" : ""}`}
      >
        {t("industryAvg")}
      </div>
      <div className="py-2 gap-2 flex flex-col">
        {data.length === 0 && (
          <span
            className={`${TYPO.TYPO7_1_MD} text-gray60  text-center h-[200px] flex items-center justify-center`}
          >
            데이터가 존재하지 않습니다
          </span>
        )}
        {data.length > 0 &&
          data.map((item: any, index: number) => (
            <div
              key={index}
              className="px-5 py-2 gap-3 flex items-center cursor-pointer "
              onClick={() => {
                router.push(`/industry/detail/${item.TU_CD}?risk=true`);
              }}
            >
              <div className="relative w-4 h-4 pl-1 pr-[5px] justify-center items-center inline-flex">
                <span
                  style={{ transform: "translateX(calc(-50% + 8px))" }}
                  className="absolute top-0 left-[0%] text-center text-neutral-700 text-[15px] font-semibold leading-none"
                >
                  {index + 1}
                </span>
              </div>
              <div className="grow flex flex-col truncate">
                <span
                  className={`${TYPO.TYPO5_1_SB} text-gray80 w-full truncate`}
                >
                  {router.locale === "ko" ? item.TU_NM : item.TU_ENG_NM}
                </span>
                <span
                  className={`${TYPO.TYPO7_1_MD} ${
                    item?.AVG_CHG?.toLocaleString("en-us", {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }) === "0.0" ||
                    item?.AVG_CHG?.toLocaleString("en-us", {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }) === "-0.0"
                      ? "text-gray60"
                      : item.AVG_CHG > 0
                      ? "text-red40"
                      : "text-blue50"
                  } `}
                >
                  {item?.AVG_CHG?.toLocaleString("en-us", {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }) === "0.0" ||
                  item?.AVG_CHG?.toLocaleString("en-us", {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }) === "-0.0"
                    ? ""
                    : item.AVG_CHG < 0
                    ? "-"
                    : ""}
                  {Math.abs(item.AVG_CHG).toLocaleString("en-us", {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }) + "%"}
                </span>
              </div>
              <span
                className={`${TYPO.TYPO6_RG} text-gray60 whitespace-nowrap`}
              >
                {router.locale === "ko" ? item.CVaR_LV_KR : item.CVaR_LV}
              </span>
              <div className="shrink-0">
                <Icon
                  name={ICONS.FORWARD}
                  height={16}
                  width={16}
                  fill="#BDC7D1"
                />
              </div>
            </div>
          ))}
      </div>
      {path !== "/industry" && data.length > 0 && (
        <DividerButton
          text={t("viewMore")}
          onClick={() => {
            router.push("/industry");
          }}
          size="md"
        />
      )}
    </div>
  );
}

export default ThemeList;
