import Image from "next/image";
import { useRef, useState } from "react";
import { TYPO } from "styles/Typo";

type inputType = "box" | "line";

interface inputProps {
  placeholder: string;
  type: inputType;
  search?: boolean;
  label?: string;
  size: string;
  width?: string;
  disabled?: boolean;
}

const InputStyle = ({
  type,
  search,
  label,
  size,
  placeholder,
  width,
  disabled,
}: inputProps) => {
  if (type === "box") {
    return (
      <div
        className={`${width} flex-col justify-start  gap-1.5 inline-flex cursor-pointer`}
      >
        {label && (
          <label
            className={`${
              size === "lg" ? TYPO.TYPO7_MD : TYPO.TYPO8_MD
            } pl-1 text-gray70`}
          >
            {label}
          </label>
        )}
        <div className={`flex relative ${width && width}`}>
          {search && (
            <div
              className={`absolute ${
                size === "lg" ? "top-[26px]" : "top-[21px]"
              } left-[8px] translate-y-[-50%] flex justify-center items-center w-[24px] h-[24px]`}
            >
              <Image
                alt="search"
                src={"/images/icons/search.svg"}
                height={18}
                width={18}
              />
            </div>
          )}
          <div
            className={`box-input ${width && width} ${
              size === "lg" ? "h-[54px]" : "h-[44px]"
            }  px-3 py-2 bg-gray2 ${
              size === "lg" ? TYPO.TYPO5_MD : TYPO.TYPO6_MD
            }  ${
              search ? "!pl-10" : "pl-3"
            } border border-slate-100 rounded-xl text-gray50 `}
          >
            {placeholder}
          </div>
        </div>
      </div>
    );
  } else if (type === "line") {
    return (
      <div
        className={`${width} flex-col justify-start  gap-1.5 inline-flex cursor-pointer`}
      >
        {label && (
          <label
            className={`${size === "lg" ? TYPO.TYPO7_MD : TYPO.TYPO8_MD}  `}
          >
            {label}
          </label>
        )}
        <div className={`flex  relative ${width}`}>
          {search && (
            <div
              className={`absolute ${
                size === "lg" ? "top-[26px]" : "top-[21px]"
              } left-[0px] translate-y-[-50%] flex justify-center items-center w-[24px] h-[24px]`}
            >
              <Image
                alt="search"
                src={"/images/icons/search.svg"}
                height={18}
                width={18}
              />
            </div>
          )}
          <div
            className={`line-input ${width} py-2 ${
              size === "lg" ? "h-[52px]" : "h-[42px]"
            }   ${size === "lg" ? TYPO.TYPO5_MD : TYPO.TYPO6_MD} ${
              search ? "pl-8" : "pl-0"
            } text-gray50 border-b-2 border-zinc-200 `}
          >
            {placeholder}
          </div>
        </div>
      </div>
    );
  } else return <></>;
};

export default InputStyle;
