import List from "components/common/List/list";
import InterestStar from "components/templates/common/components/InterestStar";
import { useSortDispatch, useSortState } from "contexts/SortContext";
import { NowTrendingData } from "interfaces/main";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useCVarRank from "utils/clientAPIs/cvarRank";
import { toDetailPage } from "utils/navigateToDetailPage";

export const useRiskTable = (type: string | undefined, isDetail = false) => {
  const router = useRouter();

  const { RISK_SORT: sort } = useSortState();
  const [order, setOrder] = useState("");
  const [data, isValid] = useCVarRank({ type, order: order, isDetail });

  const sortDispatch = useSortDispatch();
  const setSort = (sortIndex: 0 | 1 | 2 | 3) => {
    sortDispatch({ type: "SET_RISK_SORT", payload: { sort: sortIndex } });
  };
  useEffect(() => {
    if (sort === 0) {
      setOrder("TRADE_VALUE DESC");
    } else if (sort === 1) {
      setOrder("");
    } else if (sort === 2) {
      setOrder("EXP_CVaRNTS_95 DESC");
    } else if (sort === 3) {
      setOrder("EXP_CVaRNTS_95");
    }
  }, [sort]);

  const ListComponents = ({
    slice,
    onClick,
  }: {
    slice: number;
    onClick?: any;
  }) => (
    <div className="py-3 space-y-[0.5rem]">
      {data.slice(0, slice).map((asset: NowTrendingData, i: number) => (
        <div
          onClick={() => {
            if (onClick) onClick();
            toDetailPage(router, asset.ITEM_CD_DL);
          }}
          key={i}
          className="flex justify-between cursor-pointer"
        >
          <List
            num={i + 1}
            src={
              `/images/logos/${asset.ITEM_CD_DL}.png` ||
              "/images/logos/errorLogo.png"
            }
            item={
              router.locale === "ko"
                ? asset.ITEM_KR_NM && asset.ITEM_KR_NM
                : asset.ITEM_ENG_NM && asset.ITEM_ENG_NM
            }
            price={`    ${
              router.locale === "ko"
                ? ` ${
                    asset?.CVaR_LV_KR
                  }・최대 -${asset?.EXP_CVaRNTS_95?.toLocaleString("en-us", {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })}% 전망`
                : `Up to -${asset?.EXP_CVaRNTS_95?.toLocaleString("en-us", {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })}% forecast・${asset?.CVaR_LV}`
            }`}
            HR_ITEM_NM={asset.HR_ITEM_NM}
            rightIcon={() => {
              return <InterestStar asset={asset} />;
            }}
          />
        </div>
      ))}
    </div>
  );
  return { data, isValid, sort, setSort, ListComponents };
};
