import React, { useState } from "react";
import MainCard from "components/organisms/m/MainCard";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import HelpModal from "components/modals/HelpModal";
import { Circle, HelpLine } from "components/common/Icons";
import DividerButton from "components/common/divider/button";
import { TYPO } from "styles/Typo";
import dateStatus from "utils/dateStatus";
import { CLOSE, CLOSE_ENG } from "utils/constant/STATUS";
import { KOREA } from "utils/constant/ITME_INFO";

function Header({ trendingData, isHoliday }: any) {
  const router = useRouter();
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const { t } = useTranslation("main");

  //종가 날짜
  let closeDate = trendingData[0] && trendingData[0].UDT_DT;

  const formatDate = () => {
    if (!Date.parse(closeDate)) {
      console.error("Invalid date string:", closeDate);
      return "Invalid date";
    }

    const month = closeDate.split("T")[0].split("-")[1];
    const day = closeDate.split("T")[0].split("-")[2];

    return router.locale === "ko"
      ? `${month}월 ${day}일`
      : `
    ${month}.${day}
    `;
  };

  const currentStatus = dateStatus(router, KOREA, isHoliday);

  return (
    <main className="w-full bg-white">
      <div className="flex flex-col pb-5 w-full">
        <div className="gap-1 px-6 py-4">
          <div className="flex gap-1 items-center">
            <h1 className={` ${TYPO.TYPO4_B} text-gray80`}>
              {t("realtimeRiskTop30")}
            </h1>
            <div
              onClick={() => {
                setHelpModalOpen(true);
              }}
              className="cursor-pointer"
            >
              <HelpLine fill={"#878D96"} height={20} width={20} />
            </div>
          </div>
          <div className="flex flex-col ">
            <span className={`${TYPO.TYPO6_RG} text-gray60`}>
              {t("rapidlyDeclineWarning")}
            </span>
            <div
              className={`${TYPO.TYPO9_RG} text-gray50 flex gap-1 items-center`}
            >
              <span>
                {router.locale === "ko" ? formatDate() : formatDate()}{" "}
              </span>
              <div className="flex gap-[2px] items-center">
                <Circle
                  fill={
                    currentStatus === CLOSE || currentStatus === CLOSE_ENG
                      ? "#A2A9B0"
                      : "#4589FF"
                  }
                />
                <span>{currentStatus}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="w-full overflow-hidden">
            <div
              className={`${"px-[1.25rem]"} flex justify-start items-center space-x-2 overflow-x-auto`}
            >
              {trendingData.map((asset: any, i: number) => (
                <MainCard asset={asset} key={i} index={i + 1} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <DividerButton
        text={t("viewChart")}
        onClick={() => {
          router.push("/realtime-risk");
        }}
      />

      {helpModalOpen && (
        <HelpModal
          setHelpModalOpen={setHelpModalOpen}
          isModalOpen={helpModalOpen}
        />
      )}
    </main>
  );
}

export default Header;
