import List from "components/common/List/list";
import { NowTrendingData } from "interfaces/main";
import { useRouter } from "next/router";
import InterestStar from "./InterestStar";
import { toDetailPage } from "utils/navigateToDetailPage";

function ListWithInterest({
  data,
  type,
  count,
  isSearching = false,
}: {
  data: any;
  type?: string;
  count: number;
  isSearching?: boolean;
}) {
  const router = useRouter();

  return (
    <>
      {data.slice(0, count).map((asset: NowTrendingData, i: number) => (
        <div
          onClick={() => {
            if (type) {
              sessionStorage.setItem("highrisk-type", type);
            }
            toDetailPage(router, asset.ITEM_CD_DL);
          }}
          key={i}
          className="cursor-pointer flex justify-between"
        >
          <List
            num={isSearching ? undefined : i + 1}
            src={
              `/images/logos/${asset.ITEM_CD_DL}.png` ||
              "/images/logos/errorLogo.png"
            }
            item={
              router.locale === "ko"
                ? asset.ITEM_KR_NM && asset.ITEM_KR_NM
                : asset.ITEM_ENG_NM && asset.ITEM_ENG_NM
            }
            price={`    ${
              router.locale === "ko"
                ? ` ${
                    asset?.CVaR_LV_KR
                  }・최대 -${asset?.EXP_CVaRNTS_95?.toLocaleString("en-us", {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })}% 전망`
                : `Up to -${asset?.EXP_CVaRNTS_95?.toLocaleString("en-us", {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })}% forecast・${asset?.CVaR_LV}`
            }`}
            HR_ITEM_NM={asset.HR_ITEM_NM}
            rightIcon={() => {
              if (!isSearching) {
                return <InterestStar asset={asset} />;
              }
              return <></>;
            }}
          />
        </div>
      ))}
    </>
  );
}

export default ListWithInterest;
