import { ArrowForward, ICONS, Search } from "components/common/Icons";
import Button from "components/common/buttons/buttons";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import useSearchList from "utils/clientAPIs/searchList";
import SearchTable from "../../common/Search/SearchTable";
import useDebounce from "utils/useDebounce";

function MainSearch() {
  const { t } = useTranslation("main");
  const router = useRouter();

  //검색 쿼리 관련
  const [search, setSearch] = useState("");

  const onSearch = useCallback((query: string) => {
    setSearch(query);
    // debouncedSearch(query);
  }, []);
  const [debouncedValue, searchTranslated] = useDebounce(search, 250);

  const [data, isValid] = useSearchList({
    search: searchTranslated[0],
    search2: searchTranslated[1],
    searchOrigin: debouncedValue,
  });

  // console.log("searchTranslated", searchTranslated);
  // console.log("debouncedValue", debouncedValue);

  const inputRef = useRef<HTMLInputElement>(null);
  const [isForcusd, setIsForcusd] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsForcusd(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className={` flex  flex-col pt-4 bg-white`}>
      <div className="relative">
        <div className={`${isForcusd ? "h-[52px]" : ""}`}></div>
        <div
          className={`${
            isForcusd
              ? "absolute top-0 left-0 z-[6] w-full h-[640px] bg-white rounded-2xl flex-col justify-start items-start gap-3 inline-flex"
              : ""
          } mx-5 min-h-[52px]  overflow-hidden `}
          style={{
            maxWidth: "calc(100% - 40px)",
            boxShadow: isForcusd
              ? "0px 4px 12px 0px rgba(0, 0, 0, 0.20)"
              : "unset",
          }}
          ref={inputRef}
        >
          {/** 인풋 */}
          <div className={`w-full`}>
            <div
              className={`
            ${
              isForcusd
                ? "rounded-tl-xl rounded-tr-xl border-b border-zinc-200"
                : "rounded-[30px] border-2 border-blue-600"
            }
            w-full h-[52px] p-3 gap-2.5 inline-flex items-center`}
            >
              <Search fill={"#0F62FE"} height={24} width={24} />
              <input
                placeholder={t("explorePlaceholder")}
                className="grow line-input"
                onChange={(e) => onSearch(e.target.value)}
                value={search}
                onFocus={() => {
                  setIsForcusd(true);
                }}
              />
              <div className="w-6 h-6 relative">
                <div className="w-6 h-6 left-0 top-0 absolute bg-blue-600 rounded-full" />
                <div className="w-5 h-5 left-[50%] top-[50%] absolute z-[5] translate-x-[-50%] translate-y-[-50%]">
                  <ArrowForward fill={"#F7F8FA "} height={20} width={20} />
                </div>
              </div>
            </div>
          </div>
          {/** 데스크탑 연관 검색 창*/}
          {isForcusd && (
            <>
              <div className="z-[55] absolute top-[52px] w-full h-[578px] overflow-hidden">
                <SearchTable
                  search={search}
                  t={t}
                  data={data}
                  isValid={isValid}
                />
              </div>
              <div className="z-[56] absolute bottom-0 left-0 w-full h-11 px-6 py-2.5 bg-gray-50 flex-col justify-center items-end inline-flex ">
                <Button
                  clickHandler={() => {
                    setIsForcusd(false);
                  }}
                  text={router.locale === "ko" ? "닫기" : "Close"}
                  type="link_gray"
                  size="md"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MainSearch;
